import React, { useState } from 'react';
import {
    Grid, TextField, Button, Typography, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Paper, CircularProgress, Box
} from '@mui/material';
import { obterPosicoesNoPeriodo, PosicaoResponse } from '../services/posicaoAllPeriodo';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend, Filler);

const PosicaoAllPeriodo: React.FC = () => {
    const [startDate, setStartDate] = useState<string>(
        new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0]
    );
    const [endDate, setEndDate] = useState<string>(new Date().toISOString().split('T')[0]);
    const [dados, setDados] = useState<PosicaoResponse | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const calcularPosicoes = async () => {
        setLoading(true);
        try {
            const resultados = await obterPosicoesNoPeriodo(startDate, endDate);
            setDados(resultados);
        } catch (error) {
            console.error("Erro ao calcular posições:", error);
        } finally {
            setLoading(false);
        }
    };

    const prepareChartData = () => {
        if (!dados) return { labels: [], datasets: [] };

        const labels = Object.keys(dados.sumarioPorData);
        const totalBuyAmount = labels.map(date => dados.sumarioPorData[date].totalBuyAmount);
        const totalSellAmount = labels.map(date => dados.sumarioPorData[date].totalSellAmount);
        const percentualGain = labels.map(date => dados.sumarioPorData[date].percentualGain);

        let ganhoAcumulado = 0;
        const ganhoAcumuladoData = labels.map((date) => {
            const totalDifferenceForDay = Number(dados.sumarioPorData[date].totalDifference);
            ganhoAcumulado += totalDifferenceForDay;
            return ganhoAcumulado;
        });

        return {
            labels,
            datasets: [
                {
                    label: 'Total de Compras',
                    data: totalBuyAmount,
                    borderColor: 'rgba(75, 192, 192, 1)',
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    fill: true,
                },
                {
                    label: 'Total de Vendas',
                    data: totalSellAmount,
                    borderColor: 'rgba(255, 99, 132, 1)',
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    fill: true,
                },
                {
                    label: 'Percentual de Ganho',
                    data: percentualGain,
                    borderColor: 'rgba(255, 206, 86, 1)',
                    backgroundColor: 'rgba(255, 206, 86, 0.2)',
                    fill: true,
                },
                {
                    label: 'Ganho Acumulado',
                    data: ganhoAcumuladoData,
                    borderColor: 'rgba(54, 162, 235, 1)',
                    backgroundColor: 'rgba(54, 162, 235, 0.2)',
                    fill: true,
                },
            ],
        };
    };

    const calcularResumoDiario = () => {
        if (!dados) return [];

        return Object.entries(dados.sumarioPorData).map(([date, resumo]) => ({
            date,
            totalDifference: Number(resumo.totalDifference),
        }));
    };

    const calcularLucro = (posicoes: any[]) => {
        let totalLucro = 0;

        posicoes.forEach((item) => {
            const buyTotal = item.buyResumo.totalQty * item.buyResumo.price;
            const sellTotal = item.sellResumo.totalQty * item.sellResumo.price;
            const comissoes = item.buyResumo.totalCommission + item.sellResumo.totalCommission;
            const lucro = sellTotal - buyTotal - comissoes;
            totalLucro += lucro;
        });

        return totalLucro;
    };

    const formatarValor = (valor: number) => {
        return valor.toLocaleString('pt-BR', { minimumFractionDigits: 8, maximumFractionDigits: 8 });
    };


    const formatarValor2 = (valor: number) => {
        return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    };


    const formatarValor5 = (valor: number) => {
        return valor.toLocaleString('pt-BR', { minimumFractionDigits: 5, maximumFractionDigits: 5 });
    };



    const exportarDados = () => {
        if (!dados) return;

        let conteudo = "Data;Solic ID;Symbol;Tipo;Total Qty;Price;Total Amount;Total Commission;Lucro\n";
        Object.entries(dados.groupedByDate).forEach(([date, posicoes]) => {
            conteudo += `\n${date}\n`;
            posicoes.forEach((item) => {
                const buyTotal = formatarValor(item.buyResumo.totalQty * item.buyResumo.price);
                const sellTotal = formatarValor(item.sellResumo.totalQty * item.sellResumo.price);
                const lucro = formatarValor(
                    item.sellResumo.totalQty * item.sellResumo.price -
                    item.buyResumo.totalQty * item.buyResumo.price -
                    item.buyResumo.totalCommission - item.sellResumo.totalCommission
                );

                conteudo += `${date};${item.solicId};${item.symbol};Buy;${formatarValor(item.buyResumo.totalQty)};${formatarValor(item.buyResumo.price)};${buyTotal};${formatarValor(item.buyResumo.totalCommission)};- \n`;
                conteudo += `${date};${item.solicId};${item.symbol};Sell;${formatarValor(item.sellResumo.totalQty)};${formatarValor(item.sellResumo.price)};${sellTotal};${formatarValor2(item.sellResumo.totalCommission)};${lucro}\n`;
            });
        });

        const blob = new Blob([conteudo], { type: 'text/plain;charset=utf-8' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'posicoes_periodo.txt';
        a.click();
        URL.revokeObjectURL(url);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h5">Calcular Posições no Período</Typography>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    type="date"
                    label="Data Início"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    type="date"
                    label="Data Final"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" onClick={calcularPosicoes}>
                    Calcular
                </Button>
                <Button variant="contained" color="primary" onClick={exportarDados} style={{ marginLeft: '10px' }}>
                    Exportar
                </Button>
            </Grid>
            <Grid item xs={12}>
                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                        <CircularProgress />
                    </Box>
                ) : dados ? (
                    <>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Solic ID</TableCell>
                                        <TableCell>Symbol</TableCell>
                                        <TableCell>Tipo</TableCell>
                                        <TableCell>Total Qty</TableCell>
                                        <TableCell>Price</TableCell>
                                        <TableCell>Total Amount</TableCell>
                                        <TableCell>Total Commission</TableCell>
                                        <TableCell>Lucro</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.entries(dados.groupedByDate).map(([date, posicoes]) => {
                                        const lucroDoDia = calcularLucro(posicoes);
                                        return (
                                            <React.Fragment key={date}>
                                                <TableRow>
                                                    <TableCell colSpan={8}>
                                                        <Typography variant="h6">{date}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                {posicoes.map((item) => (
                                                    <React.Fragment key={item.solicId}>
                                                        <TableRow>
                                                            <TableCell>{item.solicId}</TableCell>
                                                            <TableCell>{item.symbol}</TableCell>
                                                            <TableCell>Buy</TableCell>
                                                            <TableCell>{formatarValor5(item.buyResumo.totalQty)}</TableCell>
                                                            <TableCell>{formatarValor(item.buyResumo.price)}</TableCell>
                                                            <TableCell>{formatarValor2(item.buyResumo.totalQty * item.buyResumo.price)}</TableCell>
                                                            <TableCell>{formatarValor2(item.buyResumo.totalCommission)}</TableCell>
                                                            <TableCell>-</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>{item.solicId}</TableCell>
                                                            <TableCell>{item.symbol}</TableCell>
                                                            <TableCell>Sell</TableCell>
                                                            <TableCell>{formatarValor5(item.sellResumo.totalQty)}</TableCell>
                                                            <TableCell>{formatarValor(item.sellResumo.price)}</TableCell>
                                                            <TableCell>{formatarValor2(item.sellResumo.totalQty * item.sellResumo.price)}</TableCell>
                                                            <TableCell>{formatarValor2(item.sellResumo.totalCommission)}</TableCell>
                                                            <TableCell>{formatarValor2(
                                                                item.sellResumo.totalQty * item.sellResumo.price -
                                                                item.buyResumo.totalQty * item.buyResumo.price -
                                                                item.buyResumo.totalCommission - item.sellResumo.totalCommission
                                                            )}</TableCell>
                                                        </TableRow>
                                                    </React.Fragment>
                                                ))}
                                                <TableRow>
                                                    <TableCell colSpan={4} align="right"><strong>Total do Dia</strong></TableCell>
                                                    <TableCell colSpan={4}><strong>{formatarValor2(lucroDoDia)}</strong></TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div style={{ marginTop: '20px' }}>
                            <Typography variant="h5">Gráfico de Totais por Data</Typography>
                            <Line data={prepareChartData()} options={{ responsive: true }} />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <Typography variant="h5">Resumo Diário do Total Difference</Typography>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Data</TableCell>
                                            <TableCell>Total Difference</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {calcularResumoDiario().map((resumo) => (
                                            <TableRow key={resumo.date}>
                                                <TableCell>{resumo.date}</TableCell>
                                                <TableCell>{formatarValor(resumo.totalDifference)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </>
                ) : (
                    <Typography variant="body1">Nenhum dado disponível.</Typography>
                )}
            </Grid>
        </Grid>
    );
};

export default PosicaoAllPeriodo;
