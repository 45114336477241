import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
} from '@mui/material';
import { obterPosicoesComprar, PosicaoComprar } from '../services/posicoesComprar';
import axiosInstance from '../axiosConfig';

const PosicoesComprar: React.FC = () => {
  const [posicoes, setPosicoes] = useState<PosicaoComprar[]>([]);
  const [erro, setErro] = useState<string | null>(null);
  const [carregando, setCarregando] = useState<boolean>(true);

  const fetchPosicoesComprar = async () => {
    setCarregando(true);
    try {
      const resultado = await obterPosicoesComprar();
      setPosicoes(resultado);
      setErro(null); // Limpa o erro após o carregamento bem-sucedido
    } catch (error) {
      setErro('Erro ao carregar as posições à comprar');
    } finally {
      setCarregando(false);
    }
  };

  useEffect(() => {
    fetchPosicoesComprar();
  }, []);

  const handleComprarAgora = async (solicId: string) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axiosInstance.put(
        `/forcebuy/${solicId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert(response.data.message);
    } catch (error) {
      alert('Erro ao realizar a compra.');
    }
  };

  const handleCancelarTrader = async (solicId: string) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axiosInstance.put(
        `/stoptradercoin/${solicId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert(response.data.message);
      await fetchPosicoesComprar(); // Recarrega as posições ao cancelar o trader
    } catch (error) {
      alert('Erro ao cancelar o trader.');
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4" align="center" gutterBottom>
          Posições à Comprar
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {carregando ? (
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        ) : erro ? (
          <Typography variant="body1" color="error">
            {erro}
          </Typography>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Id</strong></TableCell>
                  <TableCell><strong>Symbol</strong></TableCell>
                  <TableCell><strong>Price</strong></TableCell>
                  <TableCell><strong>Ações</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {posicoes.length > 0 ? (
                  posicoes.map((posicao) => (
                    <TableRow key={posicao.symbol}>
                      <TableCell>{posicao.solicId}</TableCell>
                      <TableCell>{posicao.symbol}</TableCell>
                      <TableCell>{posicao.price.toFixed(6)}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleComprarAgora(posicao.solicId)}
                        >
                          Comprar Agora
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleCancelarTrader(posicao.solicId)}
                          style={{ marginLeft: '8px' }}
                        >
                          Cancelar Trader
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      Nenhuma posição disponível
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </Grid>
  );
};

export default PosicoesComprar;
